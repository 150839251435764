import { EPathname } from '@src/constants/routes/index';

export const demoSlotRedirectUrlBasedOnRoute = (route, defaultRedirect) => {
  switch (route) {
    case EPathname.SOFTWARE_DEVELOPMENT_FELLOWSHIP_PROGRAM:
    case EPathname.SOFTWARE_DEVELOPMENT_FELLOWSHIP_PROGRAM_PPC_PAGE:
    case EPathname.SYSTEM_DESIGN:
      return 'https://rebrand.ly/crio-sd-on-demand';
    case EPathname.QA_AUTOMATION_TESTING:
    case EPathname.QA_PPC_PAGE:
      return 'https://rebrand.ly/crio-qa-on-demand';
    case EPathname.DATA_ANALYTICS:
    case EPathname.DATA_ANALYTICS_PPC_PAGE:
      return 'https://rebrand.ly/crio-data-analytics-on-demand';
    case EPathname.ACCELERATE:
      return 'https://rebrand.ly/crio-accelerate-on-demand';
    default:
      return defaultRedirect;
  }
};
